.matsend-main {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-left: 0%;
  margin-top: 0px;
  height: 100%;
}

.main-content-nav {
  display: flex;
  flex-direction: row;
}

@media only screen and (max-width: 600px) {
  .main-content-nav {
    flex-direction: column-reverse;
  }
}

.screen-small {
  display: none;
  @media (max-width: 1000px) {
    display: inherit;
  }
}

.screen-large {
  display: inherit;
  @media (max-width: 1000px) {
    display: none;
  }
}

@keyframes uploading {
  0% {
    opacity: 0.8;
  }
  50% {
    opacity: 0.3;
  }
}

.selected-item {
  text-decoration: underline;
}
